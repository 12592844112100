<template>
  <div v-if="showEquipamento" class="auth-content">
    <v-main>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md2>
            <v-card class="elevation-12 pa-0">
              <v-toolbar dark color="primary">
                <v-icon id="icone">mdi-login</v-icon>

                <v-toolbar-title>Login Administrador</v-toolbar-title>
              </v-toolbar>
              <v-card-text class="pb-3">
                <v-form v-model="validInicial">
                  <div class="auth-logo">
                    <v-img src="@/assets/logidoc.png" width="200" alt="200" />
                  </div>

                  <v-row>
                    <v-col cols="12" class="pa-3">
                      <v-text-field
                        v-model="user.email"
                        name="email"
                        label="E-mail"
                        required
                        outlined
                        :rules="rulesEmail"
                        hide-details="auto"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" class="pa-3">
                      <v-text-field
                        v-model="user.password"
                        label="Senha"
                        name="password"
                        required
                        outlined
                        type="password"
                        hide-details="auto"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
              <v-card-actions class="px-4 py-0 pb-1">
                <v-spacer></v-spacer>
                <v-btn color="primary" v-if="showSignup" @click="signup"
                  >Registrar</v-btn
                >
                <v-btn
                  color="primary"
                  :disabled="!validInicial"
                  v-else
                  @click="signin"
                  >Entrar</v-btn
                >
              </v-card-actions>
              <div class="d-flex justify-center mb-6">
                <a
                  href
                  @click.prevent="vaiFormularioVerificacaoEquipamentoEmail"
                >
                  <span v-if="!showEquipamento"
                    >Já tem cadastro? Acesse o Login!</span
                  >
                  <span v-else
                    >Não tem cadastro ou equipamento autorizado? Registre-se
                    aqui!</span
                  >
                </a>
              </div>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </div>
  <div v-else>
    <div v-if="verificaSituacao">
      <v-main>
        <v-container fluid fill-height>
          <v-layout align-center justify-center>
            <v-flex xs12 sm8 md2>
              <v-card class="elevation-12 pa-0">
                <v-toolbar dark color="primary">
                  <v-icon id="icone">mdi-login</v-icon>

                  <v-toolbar-title>Registro Equipamento</v-toolbar-title>
                </v-toolbar>
                <v-card-text class="pb-3">
                  <v-form v-model="valid">
                    <div class="auth-logo">
                      <v-img src="@/assets/logidoc.png" width="200" alt="200" />
                    </div>
                    <v-row>
                      <v-col cols="12" class="pa-3">
                        <v-card dark color="primary" elevation="5" outlined>
                          <v-card-text>
                            Por favor digitar o e-mail para verificar o cadastro
                            e a autorização do equipamento ! <br />
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" class="pa-3">
                        <v-text-field
                          v-model="email"
                          name="email"
                          label="E-mail"
                          required
                          outlined
                          :rules="rulesEmail"
                          hide-details="auto"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row v-if="!verifica">
                      <v-col cols="12" class="pa-3">
                        <div
                          id="inner"
                          class="drawing"
                          ref="drawing"
                          v-html="imagem"
                        ></div>
                      </v-col>
                    </v-row>

                    <v-row v-if="!verifica">
                      <v-col cols="12" class="pa-3">
                        <v-text-field
                          v-model="captcha"
                          name="captcha"
                          label="Digite o captcha"
                          required
                          outlined
                          :rules="rules"
                          hide-details="auto"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-card-text>
                <v-card-actions class="px-4 py-0 pb-1">
                  <v-spacer></v-spacer>
                  <v-btn
                    v-if="!verifica"
                    color="primary"
                    @click="verificaRegistroEquipamentoEmail"
                    :disabled="!valid"
                    >Verificar</v-btn
                  >
                </v-card-actions>
                <div class="d-flex justify-center mb-6">
                  <a href @click.prevent="verificaRegistroEquipamentoEmail">
                    <span v-if="!showEquipamento"
                      >Já tem cadastro? Acesse o Login!</span
                    >
                    <span v-else>Não tem cadastro? Registre-se aqui!</span>
                  </a>
                </div>
              </v-card>
            </v-flex>
          </v-layout>
        </v-container>
      </v-main>
    </div>
    <div v-else>
      <v-main>
        <v-container fluid fill-height>
          <v-layout align-center justify-center>
            <v-flex xs12 sm8 md2>
              <v-card class="elevation-12 pa-0">
                <v-toolbar dark color="primary">
                  <v-icon id="icone">mdi-login</v-icon>

                  <v-toolbar-title>Registro Equipamento</v-toolbar-title>
                </v-toolbar>
                <v-card-text class="pb-3">
                  <v-form v-model="valid">
                    <div class="auth-logo">
                      <v-img src="@/assets/logidoc.png" width="200" alt="200" />
                    </div>
                    <v-row v-if="!verifica">
                      <v-col cols="12" class="pa-3">
                        <v-card dark color="primary" elevation="5" outlined>
                          <v-card-text v-if="cadastradoUUID">
                            Equipamento não autorizado! <br />
                            Por favor digite os dados abaixo para registro.
                          </v-card-text>
                          <v-card-text v-else>
                            Equipamento autorizado! <br/>
                            Por favor digite os dados abaixo para cadastro do usuário.
                          </v-card-text>


                        </v-card>
                      </v-col>
                    </v-row>
                    <v-row v-else>
                      <v-col cols="12" class="pa-3">
                        <v-card dark color="primary" elevation="5" outlined>
                          <v-card-text>
                            Equipamento aguardando confirmação do email enviado
                            para o administrador! <br />
                            Tempo de validade da solicitação : {{ timerCount }}
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                    <div v-if="!cadastradoUUID">
                      <v-row>
                        <v-col cols="12" class="pa-3" v-if="!verifica">
                          <v-text-field
                            ref="textName"
                            v-model="user.name"
                            label="Nome"
                            required
                            outlined
                            hide-details="auto"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" class="pa-3">
                          <v-text-field
                            v-model="user.email"
                            name="email"
                            label="E-mail"
                            required
                            outlined
                            :rules="rulesEmail"
                            hide-details="auto"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" class="pa-3" v-if="!verifica">
                          <v-text-field
                            v-model="user.password"
                            label="Senha"
                            name="password"
                            required
                            outlined
                            type="password"
                            hide-details="auto"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" class="pa-3" v-if="!verifica">
                          <v-text-field
                            v-model="user.confirmPassword"
                            label="Confirme a Senha"
                            required
                            outlined
                            type="password"
                            hide-details="auto"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </div>
                    <div v-else>
                      <v-row>
                        <v-col cols="12" class="pa-3">
                          <v-text-field
                            v-model="email"
                            name="email"
                            label="E-mail"
                            required
                            outlined
                            :rules="rulesEmail"
                            hide-details="auto"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </div>
                    <v-row v-if="!verifica">
                      <v-col cols="12" class="pa-3">
                        <div
                          id="inner"
                          class="drawing"
                          ref="drawing"
                          v-html="imagem"
                        ></div>
                      </v-col>
                    </v-row>
                    <v-row v-if="!verifica">
                      <v-col cols="12" class="pa-3">
                        <v-text-field
                          v-model="captcha"
                          name="captcha"
                          label="Digite o captcha"
                          required
                          outlined
                          :rules="rules"
                          hide-details="auto"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-card-text>
                <v-card-actions class="px-4 py-0 pb-1">
                  <v-spacer></v-spacer>
                  <div v-if="!cadastradoUUID">
                    <v-btn
                      v-if="!verifica"
                      color="primary"
                      @click="registroUsuario"
                      :disabled="!valid"
                      >Cadastra</v-btn
                    >
                    <v-btn v-else color="primary" :disabled="false"
                      >Verifica</v-btn
                    >
                  </div>
                  <div v-else>
                    <v-btn
                      v-if="!verifica"
                      color="primary"
                      @click="registroEquipamento"
                      :disabled="!valid"
                      >Registrar</v-btn
                    >
                    <v-btn v-else color="primary" :disabled="false"
                      >Verifica</v-btn
                    >
                  </div>



                  <!-- @click="verificaEquipamento" -->
                </v-card-actions>
                <div class="d-flex justify-center mb-6">
                  <a href @click.prevent="voltaFormularioLogin">
                    <span v-if="!showEquipamento"
                      >Já tem cadastro? Acesse o Login!</span
                    >
                    <span v-else>Não tem cadastro? Registre-se aqui!</span>
                  </a>
                </div>
              </v-card>
            </v-flex>
          </v-layout>
        </v-container>
      </v-main>
    </div>
  </div>
</template>

<script>
import { baseApiUrl, showError, userKey, showSucess } from "@/global";
import axios from "axios";
import { mapMutations } from "vuex";
import { DeviceUUID } from "device-uuid";

export default {
  name: "adminAuth",
  data: function () {
    return {
      valid: false,
      validInicial: false,

      rules: [
        (value) => !!value || "Campo obrigatório",
        (value) => (value && value.length == 4) || "Digite 4 caracteres",
      ],

      rulesEmail: [
        (value) => !!value || "Campo obrigatório",
        (value) => (value && value.length >= 3) || "Mínimo 3 caracteres",
        (value) => this.validEmail(value) || "Utilize um e-mail válido.",
      ],
      showSignup: false,
      user: {},
      uuid: "",
      equipamentos: [],
      imagem: "",
      email: "",
      secret: "",
      captcha: "",
      cookieID: "",
      showEquipamento: true,
      verifica: false,
      timerCount: 0,
      timerVerifica: 10,
      testaEmailUUId: false,
      cadastradoUsuario: false,
      cadastradoUUID: false,
      cadastroConfirmacao: false,
      verificaSituacao: true,
    };
  },
  watch: {
    timerCount: {
      handler(value) {
        if (value > 0) {
          setTimeout(() => {
            this.timerCount--;
            this.timerVerifica--;
            if (this.timerVerifica == 0) {
              console.log("verifica " + this.timerCount);
              this.timerVerifica = 10;
            }
          }, 1000);
        } else {
          this.verifica = false;
          this.Getcaptcha();
        }
      },
      immediate: true, // This ensures the watcher is triggered upon creation
    },
  },
  methods: {
    ...mapMutations({
      setAuthStatus: "setAuthStatus",
    }),
    geraUUID() {
      var newuuid = new DeviceUUID().get();
      this.uuid = newuuid;
    },
    vaiFormularioVerificacaoEquipamentoEmail() {
      this.showEquipamento = false;
      this.verificaSituacao = true;
    },
    voltaFormularioLogin() {
      this.showEquipamento = true;
    },

    // async verificaUUID() {
    //   await axios
    //     .get(
    //       `${baseApiUrl}/dashboard_empresa/equipamentos?equipamento=${this.uuid}`
    //     )
    //     .then((res) => {
    //       this.equipamentos = res.data;
    //       if (this.equipamentos.length == 0) {
    //         this.Getcaptcha();
    //       } else {
    //         console.log();
    //         this.verifica = true;
    //         return true;
    //       }
    //     });
    // },

    async verificaUUID_Email() {
      await axios
        .post(
          `${baseApiUrl}/dashboard_empresa/equipamentos?equipamento=${this.uuid}&email=${this.user.email}`
        )
        .then((res) => {
          this.equipamentos = res.data;
          if (this.equipamentos.length == 0) {
            this.showEquipamento = false;
            this.email = this.user.email;
            this.Getcaptcha();
            this.testaEmailUUId = false;
          } else {
            if (this.equipamentos[0].status == 0) {
              this.showEquipamento = false;
              this.verifica = true;
              this.email = this.user.email;
              var newDate = new Date(this.equipamentos[0].date_expired);
              this.timerCount = Math.trunc((newDate - Date.now()) / 1000);
              this.testaEmailUUId = false;
            } else {
              this.testaEmailUUId = true;
            }
          }
        });
    },

    async Getcaptcha() {
      await axios.get(`${baseApiUrl}/captcha`).then((res) => {
        this.imagem = res.data.captcha;
        this.secret = res.data.secret;

        // if (this.imagem.length == 0) {

        // } else {

        // }
      });
    },
    async signin() {
      
        this.user["uuid"] = this.uuid;

        await axios
          .post(`${baseApiUrl}/admin/signin`, this.user)
          .then((res) => {
            this.$store.commit("setUser", res.data);

            localStorage.setItem(userKey, JSON.stringify(res.data));
            this.setAuthStatus(true);

            console.log(this.$store.state.authStatus);

            // this.$router.push({ path: "/pages/home" });
            this.$router.push({ path: "/" });
          })
          .catch(showError);
    },
    signup() {
      axios
        .post(`${baseApiUrl}/signup`, this.user)
        .then(() => {
          showSucess();
          this.user = {};
          this.showSignup = false;
        })
        .catch(showError);
    },

    async verificaRegistroEquipamentoEmail() {
      this.cadastradoUUID = false;
      this.cadastradoUsuario = false;
      this.cadastroConfirmacao = false;
      console.log("verificaRegistroEquipamentoEmail");

      let objetoJson = {};
      objetoJson["email"] = this.email;
      objetoJson["captcha"] = this.captcha;
      objetoJson["secret"] = this.secret;
      objetoJson["uuid"] = this.uuid;

      await axios
        .post(
          `${baseApiUrl}/dashboard_empresa/equipamentos?equipamento=${this.uuid}&email=${this.email}`,
          objetoJson
        )

        .then((res) => {
          this.equipamentos = res.data;
          if (this.equipamentos.codigo <= 0) {
            showError(this.equipamentos.message);
            this.showEquipamento = false;
            this.Getcaptcha();
            this.cadastradoUUID = false;
            this.testaEmailUUId = false;
          } else {
            if (this.equipamentos.equipamentos.length == 0) {
              this.cadastradoUUID = true;
            } else {
              if (this.equipamentos.equipamentos[0].status == 0) {
                console.log(this.equipamentos.equipamentos[0].date_expired);
                if (
                  this.equipamentos.equipamentos[0].date_expired >= Date.now()
                ) {
                  this.cadastroConfirmacao = true;
                } else {
                  this.cadastradoUUID = true;
                }
              } else {
                this.cadastradoUUID = false;
              }
            }
            if (this.equipamentos.email == "") {
              this.cadastradoUsuario = true;
            } else {
              this.cadastradoUsuario = false;
            }
            this.verificaSituacao = false;
            this.Getcaptcha();
            this.captcha = "";
          }
        })
        .catch(showError);

      // if (this.showEquipamento) {
      //   this.showEquipamento = false;
      // } else {
      //   await axios
      //     .get(`${baseApiUrl}/?email=${this.user.email}`)
      //     .then((res) => {
      //       if (res.data.length > 0) {
      //         this.cadastradoUsuario = true;
      //       }
      //     });

      //   await axios
      //     .get(
      //       `${baseApiUrl}/dashboard_empresa/equipamentos?equipamento=${this.uuid}&email=${this.user.email}`
      //     )
      //     .then((res) => {
      //       this.equipamentos = res.data;
      //       if (this.equipamentos.length == 0) {
      //         this.showEquipamento = false;
      //         this.Getcaptcha();
      //         this.cadastradoUUID = true;
      //         this.testaEmailUUId = false;
      //       } else {
      //         if (this.equipamentos[0].status == 0) {
      //           this.showEquipamento = false;
      //           this.verifica = true;
      //           this.cadastroConfirmacao = true;
      //           var newDate = new Date(this.equipamentos[0].date_expired);
      //           this.timerCount = Math.trunc((newDate - Date.now()) / 1000);
      //           this.testaEmailUUId = false;
      //         } else {
      //           this.testaEmailUUId = true;
      //         }
      //       }
      //     })
      //     .catch(showError);

      //   if (this.verifica) {
      //     console.log("equipamento já existe");
      //   } else {
      //     console.log("equipamento não existe");
      //   }

      //   this.showEquipamento = true;
      // }
    },
    async registroUsuario() {
      let objetoJson = {};
      objetoJson["user"] = this.user;
      objetoJson["captcha"] = this.captcha;
      objetoJson["secret"] = this.secret;
      objetoJson["uuid"] = this.uuid;
      // showSucess("Registro enviado com sucesso!");

      // let config = {
      //   headers: {
      //     Cookie: "connect.sid=s%3AyR2lZb7UtP3D1cW_fVrsLp_8IHTrh9Ec.wGp%2FUAxWPoQzeGC8ooAb9gN%2F0KkbHGZiVBIPPmiJ4Y0",
      //   },
      // };

      await axios
        .post(`${baseApiUrl}/verify-captcha-usuario`, objetoJson)
        .then((res) => {
          if (res.data.codigo > 0) {
            showSucess(res.data.message);
            this.verifica = true;
            console.log("resposta verificar captha usuario "+ res.data.message);
          } else {
            var msgError = '';
            msgError = res.data.message;
            showError(msgError);
            console.log("resposta erro verificar captha usuario "+ res.data.message);
            this.limpaForm();
          }
        })
        .catch(showError);
    },    

    async registroEquipamento() {
      let objetoJson = {};
      objetoJson["email"] = this.email;
      objetoJson["captcha"] = this.captcha;
      objetoJson["secret"] = this.secret;
      objetoJson["uuid"] = this.uuid;
      // showSucess("Registro enviado com sucesso!");

      // let config = {
      //   headers: {
      //     Cookie: "connect.sid=s%3AyR2lZb7UtP3D1cW_fVrsLp_8IHTrh9Ec.wGp%2FUAxWPoQzeGC8ooAb9gN%2F0KkbHGZiVBIPPmiJ4Y0",
      //   },
      // };

      await axios
        .post(`${baseApiUrl}/verify-captcha`, objetoJson)
        .then((res) => {
          if (res.data.codigo > 0) {
            showSucess(res.data.message);
            this.verifica = true;
          } else {
            showError(res.data.message);
            this.limpaForm();
          }
        })
        .catch(showError);
    },
    limpaForm() {
      this.Getcaptcha();
      this.captcha = "";
    },
    validEmail(email) {
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      console.log(re.test(email));
      return re.test(email);
    },
  },
  created() {
    this.geraUUID();
    // this.verificaUUID();
  },
};
</script>

<style>
.auth-content {
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.auth-modal {
  background-color: #fff;
  width: 350px;
  padding: 35px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);

  display: flex;
  flex-direction: column;
  align-items: center;
}

#icone {
  padding: 10px;
}

#inner {
  display: table;
  margin: 0 auto;
  border: 1px solid black;
}
.auth-logo {
  background-color: #fff;

  display: flex;
  flex-direction: column;

  align-items: center;
}
</style>